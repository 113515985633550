.legal-content {
    margin: 0 auto;
    padding: 20px;
    background-color: #1f2937;
    /* Dark background */
    color: #cfd7df;
    /* Futuristic font color */
    
}

.legal-content h2 {
    border-bottom: 2px solid #4a90e2;
    /* Highlight color for headers */
    padding-bottom: 10px;
    margin-bottom: 20px;
}

.legal-content h3 {
    margin-top: 20px;
    color: #4a90e2;
    /* Highlight color for sub-headers */
}

.legal-content p,
.legal-content ul {
    margin-bottom: 20px;
    line-height: 1.6;
    /* Better readability */
}

.legal-content ul {
    list-style-type: disc;
    margin-left: 40px;
}

.legal-content a {
    color: #4a90e2;
    /* Futuristic link color */
    text-decoration: none;
}

.legal-content a:hover {
    text-decoration: underline;
}

.AboutBody {
    background-color: #1f2937;
    /* Dark background */
    color: #cfd7df;
    /* Futuristic font color */
    font-family: 'Roboto', sans-serif;
    /* Futuristic font */
    font-size: 16px;
    /* Futuristic font size */
    line-height: 1.6;
    /* Better readability */
    padding: 5;
    flex-grow: 1;
}

html,
body {
    margin: 0;
    padding: 0;
    height: 100%;
}