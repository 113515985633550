/* ContactForm.css */
@keyframes rainbow {
    0% {
        box-shadow: 0 0 10px rgba(255, 0, 0, 0.5),
            0 0 10px rgba(255, 127, 0, 0.5),
            0 0 15px rgba(255, 255, 0, 0.5),
            0 0 20px rgba(0, 255, 0, 0.5),
            0 0 25px rgba(0, 0, 255, 0.5),
            0 0 30px rgba(75, 0, 130, 0.5),
            0 0 35px rgba(148, 0, 211, 0.5);
    }

    14% {
        box-shadow: 0 0 10px rgba(255, 0, 0, 0.5),
            0 0 10px rgba(255, 127, 0, 0.5),
            0 0 15px rgba(255, 255, 0, 0.5),
            0 0 20px rgba(0, 255, 0, 0.5),
            0 0 25px rgba(0, 0, 255, 0.5),
            0 0 30px rgba(75, 0, 130, 0.5),
            0 0 35px rgba(148, 0, 211, 0.5);
    }

    28% {
        box-shadow: 0 0 10px rgba(148, 0, 211, 0.5),
            0 0 10px rgba(255, 0, 0, 0.5),
            0 0 15px rgba(255, 127, 0, 0.5),
            0 0 20px rgba(255, 255, 0, 0.5),
            0 0 25px rgba(0, 255, 0, 0.5),
            0 0 30px rgba(0, 0, 255, 0.5),
            0 0 35px rgba(75, 0, 130, 0.5);
    }

    42% {
        box-shadow: 0 0 10px rgba(75, 0, 130, 0.5),
            0 0 10px rgba(148, 0, 211, 0.5),
            0 0 15px rgba(255, 0, 0, 0.5),
            0 0 20px rgba(255, 127, 0, 0.5),
            0 0 25px rgba(255, 255, 0, 0.5),
            0 0 30px rgba(0, 255, 0, 0.5),
            0 0 35px rgba(0, 0, 255, 0.5);
    }

    57% {
        box-shadow: 0 0 10px rgba(0, 0, 255, 0.5),
            0 0 10px rgba(75, 0, 130, 0.5),
            0 0 15px rgba(148, 0, 211, 0.5),
            0 0 20px rgba(255, 0, 0, 0.5),
            0 0 25px rgba(255, 127, 0, 0.5),
            0 0 30px rgba(255, 255, 0, 0.5),
            0 0 35px rgba(0, 255, 0, 0.5);
    }

    71% {
        box-shadow: 0 0 10px rgba(0, 255, 0, 0.5),
            0 0 10px rgba(0, 0, 255, 0.5),
            0 0 15px rgba(75, 0, 130, 0.5),
            0 0 20px rgba(148, 0, 211, 0.5),
            0 0 25px rgba(255, 0, 0, 0.5),
            0 0 30px rgba(255, 127, 0, 0.5),
            0 0 35px rgba(255, 255, 0, 0.5);
    }

    85% {
        box-shadow: 0 0 10px rgba(255, 255, 0, 0.5),
            0 0 10px rgba(0, 255, 0, 0.5),
            0 0 15px rgba(0, 0, 255, 0.5),
            0 0 20px rgba(75, 0, 130, 0.5),
            0 0 25px rgba(148, 0, 211, 0.5),
            0 0 30px rgba(255, 0, 0, 0.5),
            0 0 35px rgba(255, 127, 0, 0.5);
    }

    100% {
        box-shadow: 0 0 10px rgba(255, 127, 0, 0.5),
            0 0 10px rgba(255, 255, 0, 0.5),
            0 0 15px rgba(0, 255, 0, 0.5),
            0 0 20px rgba(0, 0, 255, 0.5),
            0 0 25px rgba(75, 0, 130, 0.5),
            0 0 30px rgba(148, 0, 211, 0.5),
            0 0 35px rgba(255, 0, 0, 0.5);
    }
}

.contact-container {
    display: flex;
    align-items: center;
    text-align: left;
    padding: 60px;
    background-color: #090c10;
    border-radius: 20px;
    animation: rainbow 5s linear infinite;
    margin: 20px;
}

.contact-container.parent {
    display: flex;
    animation: rainbow 5s linear infinite;
}

.contact-title {
    font-size: 24px;
    color: #ffffff;
    margin-bottom: 20px;
    
}

.contact-form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.input-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
}

.input-label {
    font-size: 18px;
    color: #ffffff;
    margin-bottom: 5px;
}

.input-field {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: #007bff 1px solid;
    background-color: #333;
    color: #fff;
    border-radius: 5px;
    outline: none;
}

.input-field:focus {
    background-color: #444;
}

.submit-button {
    padding: 10px 20px;
    font-size: 18px;
    background-color: #007bff;
    color: #1a1a1a;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.submit-button:hover {
    background-color: #00ccba;
}