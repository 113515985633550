/* Futuristic font from Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Orbitron&display=swap');




@keyframes rainbow {
  0% {
    box-shadow: 0 0 10px rgba(255, 0, 0, 0.5),
      0 0 10px rgba(255, 127, 0, 0.5),
      0 0 15px rgba(255, 255, 0, 0.5),
      0 0 20px rgba(0, 255, 0, 0.5),
      0 0 25px rgba(0, 0, 255, 0.5),
      0 0 30px rgba(75, 0, 130, 0.5),
      0 0 35px rgba(148, 0, 211, 0.5);
  }

  14% {
    box-shadow: 0 0 10px rgba(255, 0, 0, 0.5),
      0 0 10px rgba(255, 127, 0, 0.5),
      0 0 15px rgba(255, 255, 0, 0.5),
      0 0 20px rgba(0, 255, 0, 0.5),
      0 0 25px rgba(0, 0, 255, 0.5),
      0 0 30px rgba(75, 0, 130, 0.5),
      0 0 35px rgba(148, 0, 211, 0.5);
  }

  28% {
    box-shadow: 0 0 10px rgba(148, 0, 211, 0.5),
      0 0 10px rgba(255, 0, 0, 0.5),
      0 0 15px rgba(255, 127, 0, 0.5),
      0 0 20px rgba(255, 255, 0, 0.5),
      0 0 25px rgba(0, 255, 0, 0.5),
      0 0 30px rgba(0, 0, 255, 0.5),
      0 0 35px rgba(75, 0, 130, 0.5);
  }

  42% {
    box-shadow: 0 0 10px rgba(75, 0, 130, 0.5),
      0 0 10px rgba(148, 0, 211, 0.5),
      0 0 15px rgba(255, 0, 0, 0.5),
      0 0 20px rgba(255, 127, 0, 0.5),
      0 0 25px rgba(255, 255, 0, 0.5),
      0 0 30px rgba(0, 255, 0, 0.5),
      0 0 35px rgba(0, 0, 255, 0.5);
  }

  57% {
    box-shadow: 0 0 10px rgba(0, 0, 255, 0.5),
      0 0 10px rgba(75, 0, 130, 0.5),
      0 0 15px rgba(148, 0, 211, 0.5),
      0 0 20px rgba(255, 0, 0, 0.5),
      0 0 25px rgba(255, 127, 0, 0.5),
      0 0 30px rgba(255, 255, 0, 0.5),
      0 0 35px rgba(0, 255, 0, 0.5);
  }

  71% {
    box-shadow: 0 0 10px rgba(0, 255, 0, 0.5),
      0 0 10px rgba(0, 0, 255, 0.5),
      0 0 15px rgba(75, 0, 130, 0.5),
      0 0 20px rgba(148, 0, 211, 0.5),
      0 0 25px rgba(255, 0, 0, 0.5),
      0 0 30px rgba(255, 127, 0, 0.5),
      0 0 35px rgba(255, 255, 0, 0.5);
  }

  85% {
    box-shadow: 0 0 10px rgba(255, 255, 0, 0.5),
      0 0 10px rgba(0, 255, 0, 0.5),
      0 0 15px rgba(0, 0, 255, 0.5),
      0 0 20px rgba(75, 0, 130, 0.5),
      0 0 25px rgba(148, 0, 211, 0.5),
      0 0 30px rgba(255, 0, 0, 0.5),
      0 0 35px rgba(255, 127, 0, 0.5);
  }

  100% {
    box-shadow: 0 0 10px rgba(255, 127, 0, 0.5),
      0 0 10px rgba(255, 255, 0, 0.5),
      0 0 15px rgba(0, 255, 0, 0.5),
      0 0 20px rgba(0, 0, 255, 0.5),
      0 0 25px rgba(75, 0, 130, 0.5),
      0 0 30px rgba(148, 0, 211, 0.5),
      0 0 35px rgba(255, 0, 0, 0.5);
  }
}

@font-face {
  font-family: 'Vampire Wars';
  src: url('./fonts/Vampire\ Wars.otf');
}
.hero-section {
  height: 100vh;
  background: url('../public/hero_banner_notext.png');
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Vampire Wars', sans-serif;
  viewport-fit: stretch;
  
}
.hero-section-text h1 {
  font-family: 'Vampire Wars', sans-serif;
  letter-spacing: 1rem;
  font-size: 10rem;
  color: #FFF;
  text-align: center;
}

.hero-section-text h2 {
  font-family: 'Vampire Wars', sans-serif;
  font-size: 500%;
  color: #747373;
  text-align: center;
  max-width: 100vw;
  
}
.hero-content {
  text-align: center;
  color: #FFF;
}

.hero-title {
  font-size: 3rem;
  margin-bottom: 1rem;
}

.hero-description {
  font-size: 1.2rem;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 2rem;
  background: rgba(0, 0, 0, 0.9);
  /* Slightly transparent background */
  backdrop-filter: blur(5px);
  /* Add some blur for a frosted glass effect */
  position: sticky;
  top: 0;
  z-index: 1000;
  animation: rainbow 5s linear infinite;
}

.nav-brand {
  font-size: 1.5rem;
  color: #FFF;
  text-decoration: none;
}

.nav-items {
  list-style-type: none;
  padding: 0;
  display: flex;
  gap: 2rem;
}

.nav-item a {
  color: #FFF;
  text-decoration: none;
  transition: color 0.3s;
}

.nav-item a:hover {
  color: #0b3d91;
}
.nav-logo {
  height: 75px;
  margin-right: 15px;
}

/* Root should be full height */
html,
body,
#root {
  height: 100%;
  margin: 0;
  font-family: 'Arial', sans-serif;
  ::-webkit-scrollbar {
      display: none;
    }
}

/* Main content wrapper should stretch between navbar and footer */
.main-content {
  min-height: calc(100% -
      /*Height of Navbar*/
      60px -
      /*Height of Footer*/
      40px);
  /* Adjust above values based on the actual height of your navbar and footer */
}

/* Example media query for tablets */ 
@media (max-width: 768px) {
  .hero-section {
    background-image: url('../public/hero_banner-768.png');
  }
}

/* Example media query for mobile devices */
@media (max-width: 480px) {
  .hero-section {
    background-image: url('../public/hero_banner-480.png');
  }
}

/* hide scrollbar for chrome, safari and opera */
.container::-webkit-scrollbar {
  display: none;
}