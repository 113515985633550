/* src/components/StackedContainer.css */
@keyframes rainbow {
    0% {
        box-shadow: 0 0 10px rgba(255, 0, 0, 0.5),
            0 0 10px rgba(255, 127, 0, 0.5),
            0 0 15px rgba(255, 255, 0, 0.5),
            0 0 20px rgba(0, 255, 0, 0.5),
            0 0 25px rgba(0, 0, 255, 0.5),
            0 0 30px rgba(75, 0, 130, 0.5),
            0 0 35px rgba(148, 0, 211, 0.5);
    }

    14% {
        box-shadow: 0 0 10px rgba(255, 0, 0, 0.5),
            0 0 10px rgba(255, 127, 0, 0.5),
            0 0 15px rgba(255, 255, 0, 0.5),
            0 0 20px rgba(0, 255, 0, 0.5),
            0 0 25px rgba(0, 0, 255, 0.5),
            0 0 30px rgba(75, 0, 130, 0.5),
            0 0 35px rgba(148, 0, 211, 0.5);
    }

    28% {
        box-shadow: 0 0 10px rgba(148, 0, 211, 0.5),
            0 0 10px rgba(255, 0, 0, 0.5),
            0 0 15px rgba(255, 127, 0, 0.5),
            0 0 20px rgba(255, 255, 0, 0.5),
            0 0 25px rgba(0, 255, 0, 0.5),
            0 0 30px rgba(0, 0, 255, 0.5),
            0 0 35px rgba(75, 0, 130, 0.5);
    }

    42% {
        box-shadow: 0 0 10px rgba(75, 0, 130, 0.5),
            0 0 10px rgba(148, 0, 211, 0.5),
            0 0 15px rgba(255, 0, 0, 0.5),
            0 0 20px rgba(255, 127, 0, 0.5),
            0 0 25px rgba(255, 255, 0, 0.5),
            0 0 30px rgba(0, 255, 0, 0.5),
            0 0 35px rgba(0, 0, 255, 0.5);
    }

    57% {
        box-shadow: 0 0 10px rgba(0, 0, 255, 0.5),
            0 0 10px rgba(75, 0, 130, 0.5),
            0 0 15px rgba(148, 0, 211, 0.5),
            0 0 20px rgba(255, 0, 0, 0.5),
            0 0 25px rgba(255, 127, 0, 0.5),
            0 0 30px rgba(255, 255, 0, 0.5),
            0 0 35px rgba(0, 255, 0, 0.5);
    }

    71% {
        box-shadow: 0 0 10px rgba(0, 255, 0, 0.5),
            0 0 10px rgba(0, 0, 255, 0.5),
            0 0 15px rgba(75, 0, 130, 0.5),
            0 0 20px rgba(148, 0, 211, 0.5),
            0 0 25px rgba(255, 0, 0, 0.5),
            0 0 30px rgba(255, 127, 0, 0.5),
            0 0 35px rgba(255, 255, 0, 0.5);
    }

    85% {
        box-shadow: 0 0 10px rgba(255, 255, 0, 0.5),
            0 0 10px rgba(0, 255, 0, 0.5),
            0 0 15px rgba(0, 0, 255, 0.5),
            0 0 20px rgba(75, 0, 130, 0.5),
            0 0 25px rgba(148, 0, 211, 0.5),
            0 0 30px rgba(255, 0, 0, 0.5),
            0 0 35px rgba(255, 127, 0, 0.5);
    }

    100% {
        box-shadow: 0 0 10px rgba(255, 127, 0, 0.5),
            0 0 10px rgba(255, 255, 0, 0.5),
            0 0 15px rgba(0, 255, 0, 0.5),
            0 0 20px rgba(0, 0, 255, 0.5),
            0 0 25px rgba(75, 0, 130, 0.5),
            0 0 30px rgba(148, 0, 211, 0.5),
            0 0 35px rgba(255, 0, 0, 0.5);
    }
}

.stacked-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 100%;
    min-height: 10%;

}

.stacked-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 2%;
    margin-bottom: 2%;
    border-radius: 25px;
    min-width: 95%;
    min-height: 25%;
    gap: 2%;
    animation: rainbow 5s linear infinite;
}

.stacked-item.left {
    flex-direction: row;
}

.stacked-item.right {
    flex-direction: row-reverse;
}

img {
    max-width: 200%;
    max-height: 200px;
}