@keyframes rainbow {
    0% {
        box-shadow: 0 0 10px rgba(255, 0, 0, 0.5),
            0 0 10px rgba(255, 127, 0, 0.5),
            0 0 15px rgba(255, 255, 0, 0.5),
            0 0 20px rgba(0, 255, 0, 0.5),
            0 0 25px rgba(0, 0, 255, 0.5),
            0 0 30px rgba(75, 0, 130, 0.5),
            0 0 35px rgba(148, 0, 211, 0.5);
    }

    14% {
        box-shadow: 0 0 10px rgba(255, 0, 0, 0.5),
            0 0 10px rgba(255, 127, 0, 0.5),
            0 0 15px rgba(255, 255, 0, 0.5),
            0 0 20px rgba(0, 255, 0, 0.5),
            0 0 25px rgba(0, 0, 255, 0.5),
            0 0 30px rgba(75, 0, 130, 0.5),
            0 0 35px rgba(148, 0, 211, 0.5);
    }

    28% {
        box-shadow: 0 0 10px rgba(148, 0, 211, 0.5),
            0 0 10px rgba(255, 0, 0, 0.5),
            0 0 15px rgba(255, 127, 0, 0.5),
            0 0 20px rgba(255, 255, 0, 0.5),
            0 0 25px rgba(0, 255, 0, 0.5),
            0 0 30px rgba(0, 0, 255, 0.5),
            0 0 35px rgba(75, 0, 130, 0.5);
    }

    42% {
        box-shadow: 0 0 10px rgba(75, 0, 130, 0.5),
            0 0 10px rgba(148, 0, 211, 0.5),
            0 0 15px rgba(255, 0, 0, 0.5),
            0 0 20px rgba(255, 127, 0, 0.5),
            0 0 25px rgba(255, 255, 0, 0.5),
            0 0 30px rgba(0, 255, 0, 0.5),
            0 0 35px rgba(0, 0, 255, 0.5);
    }

    57% {
        box-shadow: 0 0 10px rgba(0, 0, 255, 0.5),
            0 0 10px rgba(75, 0, 130, 0.5),
            0 0 15px rgba(148, 0, 211, 0.5),
            0 0 20px rgba(255, 0, 0, 0.5),
            0 0 25px rgba(255, 127, 0, 0.5),
            0 0 30px rgba(255, 255, 0, 0.5),
            0 0 35px rgba(0, 255, 0, 0.5);
    }

    71% {
        box-shadow: 0 0 10px rgba(0, 255, 0, 0.5),
            0 0 10px rgba(0, 0, 255, 0.5),
            0 0 15px rgba(75, 0, 130, 0.5),
            0 0 20px rgba(148, 0, 211, 0.5),
            0 0 25px rgba(255, 0, 0, 0.5),
            0 0 30px rgba(255, 127, 0, 0.5),
            0 0 35px rgba(255, 255, 0, 0.5);
    }

    85% {
        box-shadow: 0 0 10px rgba(255, 255, 0, 0.5),
            0 0 10px rgba(0, 255, 0, 0.5),
            0 0 15px rgba(0, 0, 255, 0.5),
            0 0 20px rgba(75, 0, 130, 0.5),
            0 0 25px rgba(148, 0, 211, 0.5),
            0 0 30px rgba(255, 0, 0, 0.5),
            0 0 35px rgba(255, 127, 0, 0.5);
    }

    100% {
        box-shadow: 0 0 10px rgba(255, 127, 0, 0.5),
            0 0 10px rgba(255, 255, 0, 0.5),
            0 0 15px rgba(0, 255, 0, 0.5),
            0 0 20px rgba(0, 0, 255, 0.5),
            0 0 25px rgba(75, 0, 130, 0.5),
            0 0 30px rgba(148, 0, 211, 0.5),
            0 0 35px rgba(255, 0, 0, 0.5);
    }
}

.store-section {
    display: flex;
    justify-content: center;
    gap: 2rem;
    padding: 2rem;
    background-color: rgba(0, 0, 0, 0.7);
    flex-grow: 1;
    height: 700px;
    position: sticky;
        bottom: 0;
        align-self: flex-end;
    /* This ensures that the store-section will take up all available space */
}

.product-container {
    background-color: rgba(10, 10, 10, 0.9);
    padding: 1rem;
    border-radius: 10px;
    width: 300px;
    color: #FFF;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    animation: rainbow 5s linear infinite;
}

button {
    padding: 0.5rem 1rem;
    color: #FFF;
    background-color: #007BFF;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

button:hover {
    background-color: #0056b3;
}

.product-image {
    width: 100%;
    height: 100%;
    object-fit: stretch;
    /* This ensures the image covers the set height and width without distortion */
    border-radius: 10px;
    margin-bottom: 1rem;
}
