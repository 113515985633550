.contact-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 16px;
    color: #ffffff;
    margin-bottom: 20px;
}

.contact-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}

.contact-form {
    display: flex;
    flex-direction: column;
    align-items: center;
}
