@keyframes rainbow {
    0% {
        box-shadow: 0 0 10px rgba(255, 0, 0, 0.5),
            0 0 10px rgba(255, 127, 0, 0.5),
            0 0 15px rgba(255, 255, 0, 0.5),
            0 0 20px rgba(0, 255, 0, 0.5),
            0 0 25px rgba(0, 0, 255, 0.5),
            0 0 30px rgba(75, 0, 130, 0.5),
            0 0 35px rgba(148, 0, 211, 0.5);
    }

    14% {
        box-shadow: 0 0 10px rgba(255, 0, 0, 0.5),
            0 0 10px rgba(255, 127, 0, 0.5),
            0 0 15px rgba(255, 255, 0, 0.5),
            0 0 20px rgba(0, 255, 0, 0.5),
            0 0 25px rgba(0, 0, 255, 0.5),
            0 0 30px rgba(75, 0, 130, 0.5),
            0 0 35px rgba(148, 0, 211, 0.5);
    }

    28% {
        box-shadow: 0 0 10px rgba(148, 0, 211, 0.5),
            0 0 10px rgba(255, 0, 0, 0.5),
            0 0 15px rgba(255, 127, 0, 0.5),
            0 0 20px rgba(255, 255, 0, 0.5),
            0 0 25px rgba(0, 255, 0, 0.5),
            0 0 30px rgba(0, 0, 255, 0.5),
            0 0 35px rgba(75, 0, 130, 0.5);
    }

    42% {
        box-shadow: 0 0 10px rgba(75, 0, 130, 0.5),
            0 0 10px rgba(148, 0, 211, 0.5),
            0 0 15px rgba(255, 0, 0, 0.5),
            0 0 20px rgba(255, 127, 0, 0.5),
            0 0 25px rgba(255, 255, 0, 0.5),
            0 0 30px rgba(0, 255, 0, 0.5),
            0 0 35px rgba(0, 0, 255, 0.5);
    }

    57% {
        box-shadow: 0 0 10px rgba(0, 0, 255, 0.5),
            0 0 10px rgba(75, 0, 130, 0.5),
            0 0 15px rgba(148, 0, 211, 0.5),
            0 0 20px rgba(255, 0, 0, 0.5),
            0 0 25px rgba(255, 127, 0, 0.5),
            0 0 30px rgba(255, 255, 0, 0.5),
            0 0 35px rgba(0, 255, 0, 0.5);
    }

    71% {
        box-shadow: 0 0 10px rgba(0, 255, 0, 0.5),
            0 0 10px rgba(0, 0, 255, 0.5),
            0 0 15px rgba(75, 0, 130, 0.5),
            0 0 20px rgba(148, 0, 211, 0.5),
            0 0 25px rgba(255, 0, 0, 0.5),
            0 0 30px rgba(255, 127, 0, 0.5),
            0 0 35px rgba(255, 255, 0, 0.5);
    }

    85% {
        box-shadow: 0 0 10px rgba(255, 255, 0, 0.5),
            0 0 10px rgba(0, 255, 0, 0.5),
            0 0 15px rgba(0, 0, 255, 0.5),
            0 0 20px rgba(75, 0, 130, 0.5),
            0 0 25px rgba(148, 0, 211, 0.5),
            0 0 30px rgba(255, 0, 0, 0.5),
            0 0 35px rgba(255, 127, 0, 0.5);
    }

    100% {
        box-shadow: 0 0 10px rgba(255, 127, 0, 0.5),
            0 0 10px rgba(255, 255, 0, 0.5),
            0 0 15px rgba(0, 255, 0, 0.5),
            0 0 20px rgba(0, 0, 255, 0.5),
            0 0 25px rgba(75, 0, 130, 0.5),
            0 0 30px rgba(148, 0, 211, 0.5),
            0 0 35px rgba(255, 0, 0, 0.5);
    }
}

.footer {
    background-color: rgba(10, 10, 10, 0.9);
    color: #FFF;
    padding: 20px 0;
    box-shadow: 0 -10px 20px #007aff80;
    /* Optional: Add a glowing blue shadow for a futuristic feel */
    position: relative;
    bottom: 0;
    width: 100%;
    animation: rainbow 5s linear infinite;
}

.footer-content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
    text-align: center;
}

/* ... existing styles ... */

.footer-links {
    margin-top: 10px;
}

.footer-links a {
    color: #FFF;
    text-decoration: none;
    margin: 0 10px;
    transition: color 0.3s;
}

.footer-links a:hover {
    color: #00aaff;
    /* Change color on hover for a futuristic feel */
}